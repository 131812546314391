import React from 'react';
import * as Icon from 'react-feather';

const horizontalMenuConfig = [
	{
		id: 'home',
		title: 'Home',
		type: 'item',
		icon: <Icon.Home size={20} />,
		permissions: ['ADMIN', 'FLORIST'],
		navLink: '/'
	},
	{
		id: 'commands',
		title: 'Commandes',
		type: 'item',
		icon: <Icon.File size={20} />,
		permissions: ['ADMIN', 'FLORIST'],
		navLink: '/commandes'
	},
	{
		id: 'productList',
		title: 'Liste de produits',
		type: 'item',
		icon: <Icon.File size={20} />,
		permissions: ['ADMIN', 'FLORIST'],
		navLink: '/productList'
	},
	{
		id: 'categories',
		title: 'Catégories',
		type: 'item',
		icon: <Icon.Circle size={10} />,
		navLink: '/categories',
		permissions: ['ADMIN']
	},
	{
		id: 'discount',
		title: 'Promotions',
		type: 'item',
		icon: <Icon.Gift size={20} />,
		permissions: ['FLORIST'],
		navLink: '/discount'
	},
	{
		id: 'clients',
		title: 'Clients',
		type: 'item',
		icon: <Icon.File size={20} />,
		permissions: ['ADMIN'],
		navLink: '/clients'
	},
	{
		id: 'pros',
		title: 'Point de vente',
		type: 'item',
		icon: <Icon.File size={20} />,
		permissions: ['ADMIN'],
		navLink: '/pros'
	},
	{
		id: 'settings',
		title: 'Paramètres',
		type: 'item',
		icon: <Icon.File size={20} />,
		permissions: ['ADMIN', 'FLORIST'],
		navLink: '/settings'
	},
	{
		id: 'banner',
		title: 'Bannières',
		type: 'item',
		icon: <Icon.Circle size={10} />,
		navLink: '/banner',
		permissions: ['ADMIN']
	}
];

export default horizontalMenuConfig;
