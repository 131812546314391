import gql from "graphql-tag";

export const ADD_COUNTRY = gql`
  mutation addCountry($input: InputCountry) {
    addCountry(input: $input) {
      id
      name
      code
      phone
      language
      currency
      emoji
    }
  }
`;

export const DELETE_COUNTRY = gql`
  mutation deleteCountry($id: ID) {
    deleteCountry(id: $id) {
      id
      name
      code
      phone
      language
      currency
    }
  }
`;
export const GET_COUNTRIES = gql`
  query {
    getCountries {
      id
      name
      code
      phone
      language
      currency
      emoji
    }
  }
`;
export const UPDATE_SETTINGS = gql`
  mutation updateDeliveryApiSetting($input: DeliveryApiKeyInput) {
    updateDeliveryApiSetting(input: $input) {
      id
    }
  }
`;
export const UPDATE_PAYMENT_KEYS = gql`
  mutation updatePaymentKeysApi($input: PaymentApiKeysInput) {
    updatePaymentKeysApi(input: $input) {
      id
    }
  }
`;
export const UPDATE_SETTINGS_PARAMS = gql`
  mutation updateSettingsParams($input: ParamsInput!) {
    updateSettingsParams(input: $input) {
      id
    }
  }
`;
export const UPDATE_GLOBAL_SETTINGS = gql`
  mutation updateGlobalSettings($input: GlobalInput) {
    updateGlobalSettings(input: $input) {
      id
    }
  }
`;

export const GET_APP_SETTINGS = gql`
  query {
    getSettings {
      delivery {
        deliveriesApiKeys {
          stuart {
            apiClientId
            apiSecret
            token
            onlyByAdmin
          }
          yper {
            apiClientId
            apiSecret
            token
            onlyByAdmin
          }
        }
      }
      stripe {
        publicKey
        privateKey
        status
        clientId
      }
      paypal {
        publicKey
        privateKey
        status
        clientId
      }
      mailConfig {
        mailHost
        mailPort
        mailEncryption
        userName
        mailPassword
        senderEmail
        senderName
      }
      showTelStoreInClientApp
      showAddressStoreInClientApp
      authorizeFreeDelivery
      authorizeExpressDelivery
      authorizeBannerAdsForStore
      paymentCash
      paymentStripe
      paymentPaypal
      stripeRequired
      paypalRequired
      cashRequired
      appName
      appLogo
      authorizeChatDirect
      authorizeDeleteSubCommentNotice
      authorizeDeleteNotice
      authorizeNoticeStore
      auhorizeNoticeAutomaticly
      limitNoticeSubComments
      authorizeNoticeProduct
      authorizeReplayOtherNotice
      updateOrderPriceWhenUpdateOrderProducts
      uniqueStore
      productsDocuments{
        required
        hidden
      }
    }
  }
`;

export const GET_SETTINGS = gql`
  query {
    myStore {
      id
      settings {
        stuartDeliver
        iDeliver
        nbKlm
        nbMaxCommands
        timeSlot
        nbKgMax
        iDrive
        paymentCash
        paymentPaypal
        paymentStripe
      }
    }
  }
`;
