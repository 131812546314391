import gql from "graphql-tag";
const CommandFragment = {
  command: gql`
    fragment CommandFragment on Command {
      id
      num
      createdAt
      state
      client {
        firstName
        lastName
      }
      amount
      state
      deliveryMode
    }
  `,
};
export const GET_COMMANDS_FLORIST = gql`
  query getCommandsFlorist($filter: CommandFilter, $skip: Int, $limit: Int) {
    getCommandsFlorist(filter: $filter, skip: $skip, limit: $limit) {
      nodes {
        ...CommandFragment
      }
      count
    }
  }
  ${CommandFragment.command}
`;

export const GET_COMMAND_BY_NUM = gql`
  query getCommandByNum($num: Int) {
    getCommandByNum(num: $num) {
      id
      florist {
        id
        name
      }

      status {
        state
        date
      }
      items {
        variant {
          id
          product
          stockManaged
          refVariation
          stockValue
          price
          weight
          values {
            label
            value
          }
        }
        product {
          id
          name
          photoUrls {
            photoUrl
          }
          description
          minPrice
        }
        quantity
        promotion {
          id
        }
      }
      client {
        id
        firstName
        lastName
        tel
        fullName
      }
      deliveryAddress {
        firstName
        lastName
        address
        deliveryMode
      }
      num
      createdAt
      totalPrice
      subtotalPrice
      deliveryMode
      deliveryTime
    }
  }
`;

export const GET_COMMAND_BY_ID = gql`
  query getCommandById($id: String) {
    getCommandById(id: $id) {
      id
      florist {
        id
        name
      }
      state
      status {
        state
        date
      }
      items {
        _id
        addons {
          addon {
            id
            name
            multiSelect
            required
            minLength
            maxLength
            addon {
              id
              name
              multiQuantity
            }
            items {
              name
              price
              addon
              itemId
              outOfStock
              checked
            }
          }
          quantity
          price
          itemId
        }
        variant {
          id
          product
          stockManaged
          refVariation
          stockValue
          price
          weight
          values {
            label
            value
          }
        }
        product {
          id
          name
          photoUrls {
            photoUrl
          }
          description
          minPrice
        }
        quantity
        promotion {
          id
        }
      }
      client {
        id
        firstName
        lastName
        tel
        fullName
        photoUrl
      }
      deliveryAddress {
        firstName
        lastName
        address
        deliveryMode
        address1
      }
      num
      createdAt
      totalPrice
      subtotalPrice
      deliveryMode
      deliveryTime
      paymentMode
      paymentState
      deliveryFee
      totalTax
    }
  }
`;
export const UPDATE_COMMAND = gql`
  mutation updateCommand($id: ID, $input: CommandInputState) {
    updateCommand(id: $id, input: $input) {
      id
    }
  }
`;
