import React from 'react';
import { LangageContext } from 'utility/context/Language';
export default function withTranslate(WrappedComponent) {
	return class extends React.Component {
		constructor(props) {
			super(props);
		}

		render() {
			return (
				<LangageContext.Consumer>
					{({ labels,settings }) => {
						return <WrappedComponent settings={settings} labels={labels} {...this.props} />;
					}}
				</LangageContext.Consumer>
			);
		}
	};
}
