import { useQuery, useMutation } from "react-apollo";
import {
  GET_SETTINGS,
  GET_APP_SETTINGS,
  UPDATE_SETTINGS,
  UPDATE_PAYMENT_KEYS,
  UPDATE_SETTINGS_PARAMS,
  GET_COUNTRIES,
  ADD_COUNTRY,
  DELETE_COUNTRY,
  UPDATE_GLOBAL_SETTINGS,
} from "graphql/tags/settings.tag";
import {
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATE,
  UPDATE_TEMPLATE_MAIL,
} from "graphql/tags/mail.tag";
import {
  GET_NOTIFICATIONS_TEMPLATES,
  UPDATE_TEMPLATE_NOTIFICATION,
  GET_NOTIFICATION_TEMPLATE,
  GET_SMS_TEMPLATES,
  GET_SMS_TEMPLATE,
  UPDATE_TEMPLATE_SMS,
} from "graphql/tags/pushNotifications.tag";
import { GET_ALL_NOTIFICATIONS } from "graphql/tags/notification.tag";
import gql from "graphql-tag";

export const useQuerySetting = () => {
  const { data, loading, ...others } = useQuery(GET_SETTINGS);

  return {
    data: data?.myStore?.settings,
    loading,
    others,
  };
};
export const useQuerySettings = () => {
  const { data, ...others } = useQuery(GET_APP_SETTINGS);
  return {
    data: data?.getSettings,
    ...others,
  };
};
export const useMutationDeliverySettings = () => {
  const [updateDeliveryApiSetting] = useMutation(UPDATE_SETTINGS);
  function updateDeliveryApi({ input }) {
    return updateDeliveryApiSetting({
      variables: {
        input,
      },
    });
  }
  return {
    updateDeliveryApi,
  };
};
export const useMuautionPaymentConfig = () => {
  const [updateSettingsKeys] = useMutation(UPDATE_PAYMENT_KEYS);
  function updatePaymentSettingsKeys({ input }) {
    return updateSettingsKeys({
      variables: {
        input,
      },
    });
  }
  return { updatePaymentSettingsKeys };
};
export const useMutationSettingsParamsConfig = () => {
  const [updateGlobal] = useMutation(UPDATE_GLOBAL_SETTINGS);
  const [updateParams] = useMutation(UPDATE_SETTINGS_PARAMS);
  function updateParamsSettings({ input }) {
    return updateParams({
      variables: {
        input,
      },
    });
  }
  function updateGlobalSettings({ input }) {
    return updateGlobal({
      variables: {
        input,
      },
    });
  }
  return { updateParamsSettings, updateGlobalSettings };
};

export const useQueryCountries = () => {
  const { data, ...rest } = useQuery(GET_COUNTRIES);
  return {
    data: data?.getCountries,
    ...rest,
  };
};
export const useMutationCountry = () => {
  const [addCountries] = useMutation(ADD_COUNTRY);
  const [deleteCountries] = useMutation(DELETE_COUNTRY);
  function addCountry({ input }) {
    return addCountries({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        let dataa = cache.readQuery({
          query: GET_COUNTRIES,
        });
        cache.writeQuery({
          query: GET_COUNTRIES,
          data: {
            ...dataa,
            getCountries: [...dataa.getCountries, data?.addCountry],
          },
        });
      },
    });
  }
  function deleteCountry(id) {
    return deleteCountries({
      variables: { id },
      update: (cache, { data }) => {
        let dataa = cache.readQuery({
          query: GET_COUNTRIES,
        });
        cache.writeQuery({
          query: GET_COUNTRIES,
          data: {
            ...dataa,
            getCountries: [...dataa.getCountries?.filter((c) => c?.id !== id)],
          },
        });
      },
    });
  }
  return { addCountry, deleteCountry };
};

export const useQueryTemplatesEmails = () => {
  const { data, ...rest } = useQuery(GET_EMAIL_TEMPLATES);
  return {
    data: data?.getTemplatesEmail,
    ...rest,
  };
};
export const useQueryTemplateEmail = (id) => {
  const { data, ...rest } = useQuery(GET_EMAIL_TEMPLATE, {
    variables: {
      id,
    },
  });
  return {
    data: data?.getTemplateEmail,
    ...rest,
  };
};

export const useMutationMailTemplate = () => {
  const [updateMail] = useMutation(UPDATE_TEMPLATE_MAIL);
  function updateMailTemplate({ id, input }) {
    return updateMail({
      variables: {
        id,
        input,
      },
    });
  }
  return { updateMailTemplate };
};

export const useQuerySmsTemplates = () => {
  const { data, ...rest } = useQuery(GET_SMS_TEMPLATES);
  return {
    data: data?.getAllTemplatesSms,
    ...rest,
  };
};
export const useQueryTemplateSms = (id) => {
  const { data, ...rest } = useQuery(GET_SMS_TEMPLATE, {
    variables: {
      id,
    },
  });
  return {
    data: data?.getTemplatesSms,
    ...rest,
  };
};

export const useQueryNotificationsTemplates = () => {
  const { data, ...rest } = useQuery(GET_NOTIFICATIONS_TEMPLATES);
  return {
    data: data?.getTemplateNotifications,
    ...rest,
  };
};
export const useQueryTemplateNotification = (id) => {
  const { data, ...rest } = useQuery(GET_NOTIFICATION_TEMPLATE, {
    variables: {
      id,
    },
  });
  return {
    data: data?.getTemplateNotification,
    ...rest,
  };
};
export const useMutationSmsTemplate = () => {
  const [updateNotif] = useMutation(UPDATE_TEMPLATE_SMS);
  function updateNotificationTemplate({ id, input }) {
    return updateNotif({
      variables: {
        id,
        input,
      },
    });
  }
  return { updateNotificationTemplate };
};

export const useMutationNotificationTemplate = () => {
  const [updateNotif] = useMutation(UPDATE_TEMPLATE_NOTIFICATION);
  function updateNotificationTemplate({ id, input }) {
    return updateNotif({
      variables: {
        id,
        input,
      },
    });
  }
  return { updateNotificationTemplate };
};
const QUERY = gql`
  query {
    getStoreTypes {
      id
      name
      photoUrl
    }
  }
`;
export const useQueryStoreTypes = () => {
  const { data, loading, ...rest } = useQuery(QUERY);
  return {
    data: data?.getStoreTypes,
    loading,
    ...rest,
  };
};
export const useMutationStoreTypes = () => {
  const ADD = gql`
    mutation addStoreType($input: StoreTypeInput) {
      addStoreType(input: $input) {
        id
        name
        photoUrl
      }
    }
  `;
  const DELETE = gql`
    mutation deleteStoreType($id: ID) {
      deleteStoreType(id: $id) {
        id
      }
    }
  `;
  const UPDATE = gql`
    mutation updateStoreType($id: ID, $input: StoreTypeInput) {
      updateStoreType(id: $id, input: $input) {
        id
        name
        photoUrl
      }
    }
  `;
  const [addType] = useMutation(ADD);
  const [deleteType] = useMutation(DELETE);
  const [updateType] = useMutation(UPDATE);
  function addStoreType({ input }) {
    return addType({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        console.log({ data });
        let oldData = cache.readQuery({ query: QUERY });
        oldData = {
          ...oldData,
          getStoreTypes: [...oldData?.getStoreTypes, data.addStoreType],
        };
        cache.writeQuery({ query: QUERY, data: oldData });
      },
    });
  }
  function deleteStoreType(id) {
    return deleteType({
      variables: {
        id,
      },
      update: (cache, { data }) => {
        let oldData = cache.readQuery({ query: QUERY });
        oldData = {
          ...oldData,
          getStoreTypes: [
            ...oldData?.getStoreTypes?.filter((t) => t?.id !== id),
          ],
        };
        cache.writeQuery({ query: QUERY, data: oldData });
      },
    });
  }
  function updateStoreType({ id, input }) {
    return updateType({
      variables: {
        id,
        input,
      },
      update: (cache, { data }) => {
        console.log({ data });
        let oldData = cache.readQuery({ query: QUERY });
        oldData = {
          ...oldData,
          getStoreTypes: [
            ...oldData?.getStoreTypes.map((currentType) =>
              currentType?.id === data?.updateStoreType?.id
                ? data?.updateStoreType
                : currentType
            ),
          ],
        };
        cache.writeQuery({ query: QUERY, data: oldData });
      },
    });
  }
  return {
    addStoreType,
    deleteStoreType,
    updateStoreType,
  };
};
