import React from "react";
import { DEFAULT_LANGAGE } from "configs/constants";
import { useMutationLangage, useQueryMe } from "hooks";
import axios from "configs/axios";
import { useQuerySettings } from "hooks/settings";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-au";
const LangageContext = React.createContext();

const langageLabels = {
  fr: "Français",
  en: "English",
};
const LangProvider = (props) => {
  const [lang, setLang] = React.useState(DEFAULT_LANGAGE);
  const [currency, setCurrency] = React.useState("€");
  const { data } = useQueryMe();
  const [labels, setLabels] = React.useState(null);
  const { updateCurrentLangage } = useMutationLangage();
  const { data: dataSettings } = useQuerySettings();
  const handleChangeLang = (lg) => {
    setLang(lg);
    updateCurrentLangage({
      langage: {
        langageCode: lg,
        langageLabel: langageLabels[lg],
      },
    });
  };

  React.useEffect(() => {
    axios.get(`/auth/lang/${lang}`).then((res) => {
      console.log({ res });
      const { labels: t } = res.data;
      if (t) {
        setLabels(JSON.parse(t));
      }
    });
    if (lang === "en") {
      moment.locale("en-au");
    } else {
      moment.locale(lang);
    }

    //setLabels(langage[lang]);
  }, [lang]);

  React.useEffect(() => {
    if (data) {
      setLang(
        data?.langage?.langageCode
          ? data?.langage?.langageCode
          : DEFAULT_LANGAGE
      );
    }
  }, [data]);
  const isVisibleCol = ({ collection=null, col=null }) => {
    if(!collection || !col) return true;
    return !dataSettings?.[collection]?.hidden?.includes(col);
  };
  return (
    <LangageContext.Provider
      value={{
        handleChangeLang,
        labels,
        lang,
        currency,
        settings: dataSettings,
        isVisibleCol
      }}
    >
      {props.children}
    </LangageContext.Provider>
  );
};

const useTranslate = () => React.useContext(LangageContext);
const useGlobalSettings = () => React.useContext(LangageContext);
export { LangageContext, LangProvider, useTranslate, useGlobalSettings };
