import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Button,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { history } from "../../../history";
import { useQueryAdminAccount, useQueryStore } from "hooks";
import Token from "configs/Token";
import { useTranslate } from "utility/context/Language";
import { Store } from "components/Auth/Auth";
import { isAdmin } from "configs/function";
import { useQueryCountries } from "hooks/settings";
import Notification from "./Notification";
import { Link, useHistory } from "react-router-dom";

const Langage = () => {
  const { lang, handleChangeLang } = useTranslate();
  const { data } = useQueryCountries();
  return (
    <UncontrolledDropdown tag="li" className="nav-item">
      <DropdownToggle tag="a" className="nav-link">
        <span className="mt-0">
          {data?.find((lg) => lg.code === lang)?.emoji}
        </span>
      </DropdownToggle>
      <DropdownMenu right>
        {data?.map((lg) => (
          <DropdownItem
            key={lg.lang}
            tag="a"
            href="#"
            onClick={() => handleChangeLang(lg.code)}
          >
            <span>{lg.emoji}</span>
            <span className="align-middle">{lg.language}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const UserDropdown = (props) => {
  const { data } = useQueryStore(Token.getStoreId());
  const { data: adminData } = useQueryAdminAccount();
  const { labels,settings } = useTranslate();
  const history =useHistory();
  function renderPhoto() {
    return isAdmin() ? adminData?.profile?.photoUrl : data?.photoUrl;
  }
  function renderName() {
    return isAdmin() ? adminData?.name : data?.name;
  }
  function logout() {
    localStorage.removeItem("token");
    history.push("/login");
    window.location.reload();
  }
  React.useEffect(() => {}, [props]);
  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle tag="a" className="nav-link dropdown-user-link">
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name text-bold-600">{renderName()}</span>
        </div>
        <span data-tour="user">
          <img
            src={renderPhoto()}
            className="round ProfilImg"
            height="40"
            width="40"
            alt="avatar"
          />
        </span>
      </DropdownToggle>
      <DropdownMenu right style={{ left: "-10px" }}>
        <DropdownItem
          tag="a"
          href="#"
          onClick={(e) => history.push("/account")}
        >
          <Icon.User size={14} className="mr-50" />
          <span className="align-middle">
            {labels?.pages?.stores?.editProfile}
          </span>
        </DropdownItem>
        {isAdmin() && settings?.uniqueStore && <DropdownItem
          tag="a"
          href="#"
          onClick={(e) => history.push("/account-admin-store")}
        >
          <Icon.User size={14} className="mr-50" />
          <span className="align-middle">
            {labels?.pages?.stores?.editMyStore}
          </span>
        </DropdownItem>}
        <Link to="/settings/password">
          <DropdownItem tag="a">
            <Icon.MessageSquare size={14} className="mr-50" />
            <span className="align-middle">
              {labels?.pages?.stores?.editPassword}
            </span>
          </DropdownItem>
        </Link>

        <DropdownItem divider />
        <DropdownItem tag="a" href="#" onClick={(e) => logout()}>
          <Icon.Power size={14} className="mr-50" />
          <span className="align-middle"> {labels?.pages?.stores?.logout}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const NavbarUser = (props) => {
  const [navbarSearch, setNavBarSearch] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const history = useHistory();
  React.useEffect(() => {
    axios.get("/api/main-search/data").then(({ data }) => {
      setSuggestions(data.searchResult);
    });
  }, []);

  const handleNavbarSearch = () => {
    setNavBarSearch(!navbarSearch);
  };

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right align-items-center">
      <Store>
        <Button.Ripple
          className="no-padding mr-2 NoMargin"
          color="white"
          onClick={() => history.push("/chat")}
        >
          <Icon.MessageCircle size={20} color="steelblue" />
        </Button.Ripple>
      </Store>

      <Langage />

      <NavItem className="nav-search" onClick={handleNavbarSearch}>
        <NavLink className="nav-link-search">
          <Icon.Search size={21} data-tour="search" />
        </NavLink>
        <div
          className={classnames("search-input", {
            open: navbarSearch,
            "d-none": navbarSearch === false,
          })}
        >
          <div className="search-input-icon">
            <Icon.Search size={17} className="primary" />
          </div>
          <Autocomplete
            className="form-control"
            suggestions={suggestions}
            filterKey="title"
            filterHeaderKey="groupTitle"
            grouped={true}
            placeholder="Explore Vuexy..."
            autoFocus={true}
            clearInput={navbarSearch}
            externalClick={(e) => {
              setNavBarSearch(false);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 27 || e.keyCode === 13) {
                setNavBarSearch(false);
                props.handleAppOverlay("");
              }
            }}
            customRender={(
              item,
              i,
              filteredData,
              activeSuggestion,
              onSuggestionItemClick,
              onSuggestionItemHover
            ) => {
              const IconTag = Icon[item.icon ? item.icon : "X"];
              return (
                <li
                  className={classnames("suggestion-item", {
                    active: filteredData.indexOf(item) === activeSuggestion,
                  })}
                  key={i}
                  onClick={(e) => onSuggestionItemClick(item.link, e)}
                  onMouseEnter={() =>
                    onSuggestionItemHover(filteredData.indexOf(item))
                  }
                >
                  <div
                    className={classnames({
                      "d-flex justify-content-between align-items-center":
                        item.file || item.img,
                    })}
                  >
                    <div className="item-container d-flex">
                      {item.icon ? (
                        <IconTag size={17} />
                      ) : item.file ? (
                        <img
                          src={item.file}
                          height="36"
                          width="28"
                          alt={item.title}
                        />
                      ) : item.img ? (
                        <img
                          className="rounded-circle mt-25"
                          src={item.img}
                          height="28"
                          width="28"
                          alt={item.title}
                        />
                      ) : null}
                      <div className="item-info ml-1">
                        <p className="align-middle mb-0">{item.title}</p>
                        {item.by || item.email ? (
                          <small className="text-muted">
                            {item.by ? item.by : item.email ? item.email : null}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    {item.size || item.date ? (
                      <div className="meta-container">
                        <small className="text-muted">
                          {item.size ? item.size : item.date ? item.date : null}
                        </small>
                      </div>
                    ) : null}
                  </div>
                </li>
              );
            }}
            onSuggestionsShown={(userInput) => {
              if (navbarSearch) {
                props.handleAppOverlay(userInput);
              }
            }}
          />
          <div className="search-input-close">
            <Icon.X
              size={24}
              onClick={(e) => {
                e.stopPropagation();
                setNavBarSearch(false);
                props.handleAppOverlay("");
              }}
            />
          </div>
        </div>
      </NavItem>
      <Notification />

      <UserDropdown {...props} />
    </ul>
  );
};
export default NavbarUser;
